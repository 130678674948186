import { register } from '@netcentric/component-loader';
import { removeBodyScroll, addBodyScroll } from 'commons/utils';
import { OPEN_CORP_NAVIGATION, OPEN_CORP_SEARCH } from 'commons/constants/events';
import config from './corp-search-bar.config';

class QuickSearch {
  constructor(el) {
    this.el = el;
    this.init();
  }

  init() {
    this.setRefs();
    this.setEventListeners();
  }

  setRefs() {
    this.els = {};
    Object.entries(config.selectors).forEach(([key, value]) => {
      this.els[key] = this.el.querySelector(value);
    });
    this.els.searchToggle = this.el.parentElement.querySelector('.corp-search-bar__toggle');
  }

  setEventListeners() {
    if (this.els.searchToggle) {
      this.els.searchToggle.addEventListener('click', () => {
        this.toggleSearch();
      });
    }

    if (this.els.closeButton) {
      this.els.closeButton.addEventListener('click', (e) => {
        e.preventDefault();
        this.closeSearch();
      });
    }

    this.els.searchInput.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        this.resetSearch();
      }
    });

    this.els.searchForm.addEventListener('submit', (e) => {
      const query = this.els.searchInput.value;
      if (!query || query.length < 3) {
        e.preventDefault();
      }
    });

    window.PubSub.subscribe(OPEN_CORP_NAVIGATION, () => {
      if (!this.el.classList.contains(config.classes.hidden)) {
        this.closeSearch();
        addBodyScroll(config.classes.noScroll); // To solve async issue between search and navigation
      }
    });
  }

  toggleSearch() {
    if (this.el.classList.contains(config.classes.hidden)) {
      this.el.classList.remove(config.classes.hidden);
      this.els.searchToggle.setAttribute('aria-expanded', 'true');
      this.resetSearch();
      addBodyScroll(config.classes.noScroll);

      window.PubSub.publish(OPEN_CORP_SEARCH);
    } else {
      this.closeSearch();
    }
  }

  closeSearch() {
    this.el.classList.add(config.classes.hidden);
    this.els.searchToggle.setAttribute('aria-expanded', 'false');
    removeBodyScroll(config.classes.noScroll);
  }

  resetSearch() {
    this.els.searchInput.value = '';
    this.focusSearchInput();
  }

  focusSearchInput() {
    this.els.searchInput.focus();
  }
}

register({ QuickSearch });
