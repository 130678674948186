export default {
  selectors: {
    searchInput: '.corp-quicksearch__input',
    searchForm: '.corp-quicksearch__form',
    closeButton: '.corp-search-bar__close',
  },
  classes: {
    noScroll: 'noScroll',
    hidden: 'hidden',
  },
};
