export const OPEN_SEARCH = 'openSearch';
export const CLOSE_SEARCH = 'closeSearch';
export const RESET_SEARCH = 'resetSearch';
export const FOCUS_SEARCH_INPUT = 'focusSearchInput';

export const TOGGLE_LANGUAGE_NAVIGATION = 'toggleMobileMenu';
export const CLOSE_LANGUAGE_NAVIGATION = 'closeMobileMneu';

export const OPEN_NAVIGATION = 'openMobileMenu';
export const CLOSE_NAVIGATION = 'closeMobileMenu';

export const OPEN_CORP_NAVIGATION = 'openCorpMenu';
export const OPEN_CORP_SEARCH = 'openCorpSearch';

// Theme UI
export const THEME_UI_UPDATED_FONT = 'updateBatcomJSONFont';
export const THEME_UI_UPDATED_VALUE = 'updateBatcomJSON';
export const THEME_UI_UPDATED_COLOR_PALETTE = 'updateColorPalette';
export const THEME_UI_CANCEL = 'cancel';
export const THEME_UI_SAVE = 'save';

// CONTENT SYNDICATION
export const CONTENT_SYNDICATION_APPLY_FILTERS = 'CONTENT_SYNDICATION_APPLY_FILTERS';

// Ecommerce Shopify events
export const DYNAMIC_LIST_RENDERED = 'DYNAMIC_LIST_RENDERED';
export const DYNAMIC_LIST_FILTERED = 'DYNAMIC_LIST_FILTERED';
export const DYNAMIC_LIST_SORTED = 'DYNAMIC_LIST_SORTED';
export const UPDATE_MINIBASKET = 'UPDATE_MINIBASKET';
export const MINIBASKET_CART_ID_SET = 'MINIBASKET_CART_ID_SET';
export const UPDATE_PRODUCT_GALLERY = 'UPDATE_PRODUCT_GALLERY';
export const ADD_PRODUCT_TO_MINIBASKET_ERROR = 'ADD_PRODUCT_TO_MINIBASKET_ERROR';

// CONSENT CHANGED
export const CONSENT_CHANGED = 'consentChanged';

// AGE GATE
export const AGE_GATE_COOKIE_SET = 'ageGateCookieSet';
